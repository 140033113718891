import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4><em parentName="h4">{`About Me`}</em></h4>
    <p>{`Passionately Curious, Deep-Thinking Human.`}</p>
    <h4><em parentName="h4">{`Everyone thinks of changing the world, but no one thinks of changing himself.`}</em>{`        ~ Leo Tolstoy`}</h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      